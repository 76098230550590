<template>
  <div class="content">
    <el-affix :offset="0">
      <div
          style="display: flex;justify-content: space-between;align-items: center;padding-left: 10px;background-color: white;width: 100%">
        <img src="../assets/logo.png" style="width: calc(100vw * 135 / 1080);height: calc(100vw * 35 / 1080);">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
          <el-tab-pane label="首页" name="first"></el-tab-pane>
          <el-tab-pane label="平台介绍" name="second"></el-tab-pane>
          <el-tab-pane label="产品与服务" name="third"></el-tab-pane>
          <el-tab-pane label="联系我们" name="fourth"></el-tab-pane>
        </el-tabs>
      </div>
    </el-affix>
    <div class="gutter1" id="first"></div>
    <div class="home">
      <div class="left">
       <div style="display: flex;flex-direction: column;justify-content: start">
         <span class="title">火啦租机  万物可租</span>
         <span class="sub-title">一站式3C数码共享租赁平台</span>
       </div>
        <div class="icon">
          <div class="ios"></div>
          <div class="android"></div>
        </div>
      </div>
      <div class="right"></div>
    </div>
    <div class="gutter" id="second"></div>
    <div class="platform">
      <div class="title">平台介绍</div>
      <div class="line"></div>
      <div class="content">
        <div class="left">
          <span>「火啦租机」</span>是杭州速飞通讯设备有限公司旗下品<br>
          牌，于2023年创立，秉承着“<span>以租代购、灵活租</span><br>
          <span>赁</span>”的信用消费新理念，提供全品牌新手机、笔记<br>
          本、数码类潮品等科技生活产品租赁，不仅能让用<br>
          户花少量的资金便可享用新机，还能避免数码产品<br>的闲置风险。
        </div>
        <div class="right"></div>
      </div>
      <div class="sub-title">生态合作伙伴</div>
      <div class="icons">
        <div class="ali"></div>
        <div class="zm"></div>
        <div class="mayi"></div>
        <div class="tencent"></div>
      </div>
    </div>

    <div class="gutter" id="third"></div>
    <div class="products">
      <div class="title">产品与服务</div>
      <div class="line"></div>
      <div class="one">
        <div class="left"></div>
        <div class="right">
          <div class="title">全天候服务支持</div>
          <div class="sub-title">平台配有在线和热线客服，满足客户多种咨询方式<br>
            快速响应客户问题，专人跟进咨询问题。
          </div>
        </div>
      </div>
      <div class="two">
        <div class="left">
          <div class="title">多种租期，灵活租用</div>
          <div class="sub-title">为客户提供多种灵活租赁的方案，日租、周租、月租、季租、以及<br>
            年租。满足不同客户的租赁需求
          </div>
        </div>
        <div class="right"></div>
      </div>
      <div class="three">
        <div class="left"></div>
        <div class="right">
          <div class="title">品类全，上新快</div>
          <div class="sub-title">手机｜电脑｜平板｜相机｜无人机｜耳机…<br>
            全方面满足用户“租享生活”理念。
          </div>
        </div>
      </div>
    </div>

    <div class="gutter" id="fourth"></div>
    <div class="about">
      <div class="title">联系我们</div>
      <div class="line"></div>
      <div class="content">
        <div class="left"></div>
        <div class="right">
          <div class="phone">
            <img src="../assets/number.webp">
            <span>商务合作热线：</span>
            16588722238
          </div>
          <div class="email">
            <img src="../assets/email.webp">
            <span>商务邮箱：</span>
            sufeitongxun888@163.com
          </div>
        </div>
      </div>
    </div>

    <div class="bottom">
      <div class="icons">
        <div class="icon b1"></div>
        <div class="icon b2"></div>
        <div class="icon b3"></div>
        <div class="icon b4"></div>
      </div>
     <div class="beian">
       <div style="display: flex;align-items: center">
         <image class="beian-icon" src="https://beian.mps.gov.cn/img/logo01.dd7ff50e.png"> </image>
         公网备案：浙公网安备33010602013294号
       </div>
       <div style="margin-bottom: 20px;margin-top: 10px">ICP备案：浙ICP备2023032360号-1</div>
     </div>
    </div>
  </div>
</template>

<script>
const {onMounted, reactive, toRefs} = require("vue");
export default {
  name: 'HelloWorld',
  setup() {
    const state = reactive({
      activeName: 'first',
    });
    const handleClick = (tab) => {
      console.log(tab.props.name)
      document.getElementById(tab.props.name).scrollIntoView();
    };
    onMounted(() => {

    });
    return {
      handleClick,
      ...toRefs(state)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.content {
  .gutter{
    height: calc(100vw * 54 / 1080);
    color: aqua;
  }
  .home {
    //padding-top: 52%;
    height: calc(100vw * 0.52);
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-image: url("../assets/img1.webp");
    display: flex;
    justify-content: center;
    align-items: center;

    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: calc(100vw * 85 / 1080);

      .title {
        font-size: calc(100vw * 45 / 1080);
        font-weight: bold;

      }

      .sub-title {
        padding-top: calc(100vw * 29 / 1080);
        padding-bottom: calc(100vw * 29 / 1080);
        color: #595959;
        font-size: calc(100vw * 26 / 1080);
        text-align: start;
      }

      .icon {
        display: flex;
        justify-content: space-between;

        .ios {
          width: calc(100vw * 154 / 1080);
          height: calc(100vw * 49 / 1080);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("../assets/ios.webp");
        }

        .android {
          width: calc(100vw * 154 / 1080);
          height: calc(100vw * 49 / 1080);
          background-repeat: no-repeat;
          background-size: 100% 100%;
          background-image: url("../assets/android.webp");
        }
      }
    }

    .right {
      height: calc(100vw / 3);
      width: calc(100vw / 2.55);
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-image: url("../assets/home.webp");
    }
  }

  .platform {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: calc(100vw * 45 / 1080);
      font-weight: bold;
    }

    .content {
      display: flex;

      .left {
        margin-top: calc(100vw * 46 / 1080);
        margin-right: calc(100vw * 69 / 1080);
        color: #595959;
        line-height: calc(100vw * 45 / 1080);
        letter-spacing: calc(100vw * 2 / 1080);
        text-align: start;
        font-size: calc(100vw * 18 / 1080);
        font-weight: 400;

        span {
          font-size: calc(100vw * 20 / 1080);
          font-weight: bold;
        }
      }

      .right {
        width: calc(100vw * 286 / 1080);
        height: calc(100vw * 518.5 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/phone.webp");
      }
    }

    .sub-title {
      font-size: calc(100vw * 30 / 1080);
      font-weight: bold;
      display: flex;
      width: 80%;
      justify-content: start;
      margin-bottom: calc(100vw * 22 / 1080);
    }

    .icons {
      display: flex;
      align-items: center;
      justify-content: start;

      .ali {
        margin-right: calc(100vw * 48 / 1080);
        width: calc(100vw * 132 / 1080);
        height: calc(100vw * 48 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/aili.webp");
      }

      .zm {
        margin-right: calc(100vw * 48 / 1080);
        width: calc(100vw * 136 / 1080);
        height: calc(100vw * 52 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/zm.webp");
      }

      .mayi {
        margin-right: calc(100vw * 48 / 1080);
        width: calc(100vw * 136 / 1080);
        height: calc(100vw * 52 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/mayi.webp");
      }

      .tencent {
        margin-right: calc(100vw * 48 / 1080);
        width: calc(100vw * 276 / 1080);
        height: calc(100vw * 36 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/tencent.webp");
      }
    }
  }

  .products {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: calc(100vw * 45 / 1080);
      font-weight: bold;
    }

    .line {
      width: calc(100vw * 250 / 1080);
      height: calc(100vw * 10 / 1080);
      background: linear-gradient(90deg, #FF9C6E 0%, #FFC069 26%, #FFD591 61%, rgba(255, 247, 230, 0.93) 100%);
    }

    .one {
      margin-top: calc(100vw * 43 / 1080);
      display: flex;

      .left {
        width: calc(100vw * 445 / 1080);
        height: calc(100vw * 295 / 1080);
        margin-right: calc(100vw * 46 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/p1.webp");
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: start;
        text-align: start;

        .title {
          font-size: calc(100vw * 26 / 1080);
          font-weight: bold;
        }

        .sub-title {
          margin-top: calc(100vw * 28 / 1080);
          font-size: calc(100vw * 18 / 1080);
          color: #8C8C8C;
          line-height: calc(100vw * 21 / 1080);
          letter-spacing: calc(100vw * 1 / 1080);
        }
      }
    }

    .two {
      margin-top: calc(100vw * 49 / 1080);
      display: flex;

      .left {
        display: flex;
        flex-direction: column;
        justify-content: start;
        text-align: start;

        .title {
          font-size: calc(100vw * 26 / 1080);
          font-weight: bold;
        }

        .sub-title {
          margin-top: calc(100vw * 19 / 1080);
          font-size: calc(100vw * 18 / 1080);
          color: #8C8C8C;
          line-height: calc(100vw * 21 / 1080);
          letter-spacing: calc(100vw * 1 / 1080);
        }
      }

      .right {
        width: calc(100vw * 275 / 1080);
        height: calc(100vw * 368 / 1080);
        margin-left: calc(100vw * 32 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/p2.webp");
      }
    }

    .three {
      margin-top: calc(100vw * 49 / 1080);
      display: flex;

      .left {
        width: calc(100vw * 276 / 1080);
        height: calc(100vw * 440 / 1080);
        margin-right: calc(100vw * 56 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/p3.webp");
      }

      .right {
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-right: calc(100vw * 179 / 1080);
        text-align: start;

        .title {
          font-size: calc(100vw * 26 / 1080);
          font-weight: bold;
        }

        .sub-title {
          margin-top: calc(100vw * 28 / 1080);
          font-size: calc(100vw * 18 / 1080);
          color: #8C8C8C;
          line-height: calc(100vw * 21 / 1080);
          letter-spacing: calc(100vw * 1 / 1080);
        }
      }
    }
  }


  .about {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: calc(100vw * 45 / 1080);
      font-weight: bold;
    }

    .content {
      display: flex;
      align-items: center;
      margin-top: calc(100vw * 40 / 1080);

      .right {
        color: #595959;
        line-height: calc(100vw * 45 / 1080);
        letter-spacing: calc(100vw * 2 / 1080);
        text-align: start;
        font-size: calc(100vw * 18 / 1080);
        font-weight: 400;

        span {
          font-size: calc(100vw * 20 / 1080);
          font-weight: bold;
        }

        .phone {
          margin-left: calc(100vw * 40 / 1080);
          display: flex;
          align-items: center;

          img {
            margin-right: calc(100vw * 14 / 1080);
            width: calc(100vw * 21 / 1080);
            height: calc(100vw * 27 / 1080);
          }
        }

        .email {
          margin-top: calc(100vw * 22 / 1080);
          margin-left: calc(100vw * 40 / 1080);
          display: flex;
          align-items: center;

          img {
            margin-right: calc(100vw * 14 / 1080);
            width: calc(100vw * 36 / 1080);
            height: calc(100vw * 25 / 1080);
          }
        }
      }

      .left {
        width: calc(100vw * 495 / 1080);
        height: calc(100vw * 301 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/a.webp");
      }
    }

    .sub-title {
      font-size: calc(100vw * 30 / 1080);
      font-weight: bold;
      display: flex;
      width: 80%;
      justify-content: start;
      margin-bottom: calc(100vw * 22 / 1080);
    }

    .icons {
      display: flex;
      align-items: center;
      justify-content: start;

      .ali {
        margin-right: calc(100vw * 48 / 1080);
        width: calc(100vw * 132 / 1080);
        height: calc(100vw * 48 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/aili.webp");
      }

      .zm {
        margin-right: calc(100vw * 48 / 1080);
        width: calc(100vw * 136 / 1080);
        height: calc(100vw * 52 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/zm.webp");
      }

      .mayi {
        margin-right: calc(100vw * 48 / 1080);
        width: calc(100vw * 136 / 1080);
        height: calc(100vw * 52 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/mayi.webp");
      }

      .tencent {
        margin-right: calc(100vw * 48 / 1080);
        width: calc(100vw * 276 / 1080);
        height: calc(100vw * 36 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/tencent.webp");
      }
    }
  }

  .bottom {
    background-color: #F7F0E4;
    margin-top: calc(100vw * 120 / 1080);

    .icons {
      justify-content: center;
      padding: calc(100vw * 40 / 1080);
      display: flex;

      .b1 {
        margin-right: calc(100vw * 40 / 1080);
        width: calc(100vw * 191 / 1080);
        height: calc(100vw * 45 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/bottom1.webp");
      }

      .b2 {
        margin-right: calc(100vw * 40 / 1080);
        width: calc(100vw * 182 / 1080);
        height: calc(100vw * 48 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/bottom2.webp");
      }

      .b3 {
        margin-right: calc(100vw * 40 / 1080);
        width: calc(100vw * 177 / 1080);
        height: calc(100vw * 45 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/bottom3.webp");
      }

      .b4 {
        margin-right: calc(100vw * 40 / 1080);
        width: calc(100vw * 178 / 1080);
        height: calc(100vw * 45 / 1080);
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-image: url("../assets/bottom4.webp");
      }
    }

    .beian{
      margin-top: calc(100vw * 10 / 1080);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      font-size: calc(100vw * 14 / 1080);
      font-weight: 500;
      .beian-icon{
        margin-right: calc(100vw * 6 / 1080);
        width: calc(100vw * 20 / 1080);
        height: calc(100vw * 20 / 1080);
      }
    }
  }

  .line {
    width: calc(100vw * 200 / 1080);
    height: calc(100vw * 10 / 1080);
    background: linear-gradient(90deg, #FF9C6E 0%, #FFC069 26%, #FFD591 61%, rgba(255, 247, 230, 0.93) 100%);
  }
}

</style>
