<template>
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  },
  created() {
    // this.resizeFun();
  },
  methods: {
    resizeFun() {
      let docEle = document.documentElement,
          resizeEvt =
              'orientationchange' in window ? 'orientationchange' : 'resize',
          resizeFun = () => {
            let clientWidth = docEle.clientWidth;
            if (!clientWidth) return;
            // 100是换算系数，相当于1rem=100px
            //1920是作为设计图的基准，我这是按照1920*1080的分辨率，可自行修改
            docEle.style.fontSize = 100 * (clientWidth / 1920) + 'px';
          };
      if (!document.addEventListener) return;
      window.addEventListener(resizeEvt, resizeFun, false);
      window.addEventListener('DOMContentLoaded', resizeFun, false);
      resizeFun();
    },
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.el-tabs__nav-wrap {
  padding: calc(100vw * 10 / 1080) !important;
}

.el-tabs__header {
  margin: 0 !important;
}

.el-tabs__nav-scroll {
  display: flex !important;
  justify-content: end !important;
  padding-right: calc(100vw * 30 / 1080);
}

.el-tabs__item.is-active {
  color: #595959 !important;
  font-weight: bold !important;
  font-size: calc(100vw * 14 / 1080) !important;
}

.el-tabs__item {
  font-size: calc(100vw * 14 / 1080) !important;
  font-weight: 400 !important;
  color: var(--el-text-color-primary);
}

.el-tabs__active-bar {
  background-color: #fff;
}

.el-tabs__item:hover {
  color: rgb(31, 31, 31) !important;
  cursor: pointer;
}

.el-tabs__nav-wrap::after {
  height: 0 !important;
}

.el-tabs {
  background: #fff !important;
}
</style>
